"use client";
import SectionHeader from "@/components/common/section-header";
import { FC, useEffect, useState } from "react";
import WorldFilled from "./svgs/world-filled";
import WorldEmpty from "./svgs/world-empty";
import useInViewPort from "@/hooks/useInViewport";
import CountUp from "react-countup";

import { homeOurExpEN } from "@/assets/dictionaries/en/home-content";
import { homeOurExpES } from "@/assets/dictionaries/es/home-content";

const Stat: FC<{
  start: boolean;
  number: number;
  description: string;
  plus?: boolean;
}> = ({ start, number, description, plus }) => {
  return (
    <div className="flex flex-col items-center gap-2 font-bold">
      <p className="text-6xl sm:text-8xl">
        <small>{plus && "+"}</small>
        {start ? <CountUp end={number} start={0} duration={3} /> : 0}
      </p>
      <p className="text-4xl">{description}</p>
    </div>
  );
};

const CountryList: FC<{ title: string; list: string[] }> = ({
  title,
  list,
}) => {
  const { ref, isInViewport } = useInViewPort<HTMLDivElement>({ once: true });

  return (
    <div
      className={`flex flex-col gap-2 ${isInViewport ? "animate-fadein" : "opacity-0"}`}
      ref={ref}
    >
      <p className="font-bold text-xl text-gbci-primary">{title}</p>
      <ul className="flex flex-wrap gap-x-8 gap-y-4 text-2xl font-bold">
        {list.map((country) => (
          <li key={country}>{country}</li>
        ))}
      </ul>
    </div>
  );
};

const OurExperience: FC = () => {
  const { ref, isInViewport } = useInViewPort<HTMLDivElement>({ once: true });
  const [ourExpContent, setOurExpContent] = useState(homeOurExpEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setOurExpContent(homeOurExpES);
        break;
      case "EN":
      default:
        setOurExpContent(homeOurExpEN);
        break;
    }
  }, []);

  return (
    <section className="py-16">
      <div className="px-page-xl">
        <SectionHeader>
          <div className="max-w-[240px] md:max-w-none">
            {ourExpContent.title_section}
          </div>
        </SectionHeader>
      </div>

      <div className="pt-8">
        <div className="grid grid-cols-1 grid-rows-1 overflow-hidden">
          <div className="scale-[0.25] sm:scale-50 md:scale-100 col-start-1 col-end-1 row-start-1 row-end-1 flex justify-center blur-sm">
            <div
              className="bg-gradient-to-br  from-gbci-accent"
              style={{
                clipPath: "url(#world-filled)",
              }}
            >
              <WorldFilled />
            </div>
          </div>
          <div className="scale-[0.25] sm:scale-50 md:scale-100 col-start-1 col-end-1 row-start-1 row-end-1 flex justify-center blur-sm">
            <div
              className="bg-gradient-to-br from-slate-100 to-slate-200 "
              style={{
                clipPath: "url(#world-empty)",
              }}
            >
              <WorldEmpty />
            </div>
          </div>

          <div className="col-start-1 col-end-1 row-start-1 row-end-1  z-10">
            <div className="h-full flex flex-col gap-16">
              <div
                className="px-page-xl flex-1 flex flex-wrap flex-col gap-16 md:flex-row sm:gap-24 justify-center items-center"
                ref={ref}
              >
                <Stat
                  start={isInViewport}
                  number={2}
                  description={ourExpContent.offices}
                />
                <Stat
                  start={isInViewport}
                  number={3000}
                  description={ourExpContent.customers}
                  plus
                />
                <Stat
                  start={isInViewport}
                  number={26000}
                  description={ourExpContent.cases}
                  plus
                />
              </div>
            </div>
          </div>

          <div className="px-page-xl pt-8 flex flex-col gap-8">
            <CountryList
              title={ourExpContent.americas}
              list={ourExpContent.americas_array}
            />

            <CountryList
              title={ourExpContent.europe}
              list={ourExpContent.europe_array}
            />

            <CountryList
              title={ourExpContent.asia}
              list={ourExpContent.asia_array}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurExperience;
