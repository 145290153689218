export const homeCarouselEN: { [key: string]: string } = {
  title_1: "Transforming ",
  title_2: "business visions into ",
  title_3: "reality",
  featuring: "Featuring",
  tool_title: "Impetus Selection Assessment",
  tool_title_label: "NEW",
  description:
    "We've created a tool that assesses how closely a company's expectations align with its willingness to put in the effort by asking carefully selected questions curated by our experts.",
  button: "Take Assessment",
  estimated: "Estimated time 10 minutes",
};

interface HomeWhatWeDo {
  title_section: string;
  card_1_title: string;
  card_1_array: string[];
  card_2_title: string;
  card_2_array: string[];
  card_3_title: string;
  card_3_array: string[];
  card_4_title: string;
  card_4_array: string[];
}

export const homeWhatWeDoEN: HomeWhatWeDo = {
  title_section: "WHAT WE DO",
  card_1_title: "Evaluation",
  card_1_array: [
    "Cultural",
    "Organizational",
    "Operational",
    "Technological",
    "Industrial",
  ],
  card_2_title: "Coaching",
  card_2_array: [
    "Strategic Planning",
    "Execution of Projects",
    "Project Management",
  ],
  card_3_title: "Training",
  card_3_array: ["Lean", "Six Sigma", "Innovation", "Agile", "Leadership"],
  card_4_title: "Mentoring",
  card_4_array: ["Change Management", "Communication", "Leadership"],
};

export const homeWhatMakeUsEN: { [key: string]: string } = {
  title_section: "WHAT MAKES US",
  section_1_title: "Business Transformation",
  section_1_sub_title: "Rethinking Technology",
  section_1_desc:
    "It is a radical redesign of a value stream to achieve breakthrough goals, which includes a re-evaluation of the use of current technology, organizational structure realignment, and enhancement of current process standards executed over a short time.",
  section_2_title: "Daily Management",
  section_2_sub_title: "Changing the Workplace",
  section_2_desc:
    "It is a metrics-based operations management system that defines a cadence for daily meetings and communication that improves accountability and facilitates problem-solving escalation of events to the proper levels.",
  section_3_title: "Continuous Improvement",
  section_3_sub_title: "Working better. Every day",
  section_3_desc:
    "It is a set of methodologies (Kaizen, DMAIC, Workout, PDCA, RCFA). that uses data or subject matter experts’ opinions to improve the product or process performance incrementally. It is organized into many small events that are planned for an extended period of time.",
  section_4_title: "Innovation",
  section_4_sub_title: "Making the impossible possible",
  section_4_desc:
    "We believe that it is possible to intentionally and systemically look for innovation. We help our clients implement different methodologies to address their efforts to identify or develop outstanding and valuable products and services that disrupt their industry or expand their scope to other markets.",
  section_5_title: "Process Automation",
  section_5_sub_title: "Doing it right, doing it fast",
  section_5_desc:
    "Once the transformation effort is consolidated in our client’s culture, we are capable of smoothly transitioning the transformed processes to automation using diverse technologies such as RPA, AI, software development, etc. to provide intuitive and valuable user experiences to ultimate customers.",
  button_contact: "Get in Contact",
};

export const homeWhatOpexEN: { [key: string]: string } = {
  title_section: "What is OPEX?",
  opex_title: "Operational Excellence",
  opex_desc_1:
    "The continuous effort towards medium- and long-term objectives to transform and innovate.",
  opex_desc_2:
    "It is a systematic approach to translating customer needs into a product or service using a new way of thinking by using new materials, designs, or even new technology.",
};

interface HomeOurExp {
  title_section: string;
  offices: string;
  customers: string;
  cases: string;
  americas: string;
  europe: string;
  asia: string;
  americas_array: string[];
  europe_array: string[];
  asia_array: string[];
}

export const homeOurExpEN: HomeOurExp = {
  title_section: "WORLDWIDE EXPERIENCE",
  offices: "Offices",
  customers: "Customers",
  cases: "Test Cases",
  americas: "Americas",
  europe: "Europe",
  asia: "Asia",
  americas_array: [
    "USA",
    "Canada",
    "Mexico",
    "Brazil",
    "Chile",
    "Argentina",
    "Colombia",
    "Venezuela",
    "Peru",
    "Costa Rica",
  ],
  europe_array: ["Germany", "Netherlands", "Spain"],
  asia_array: ["Thailand", "Japan"],
};

export const homeContactEN: { [key: string]: string } = {
  title_section: "GET IN CONTACT",
  title: "Let’s accelerate your business together",
  title_sub_title:
    "Fill out the form, and get in contact with our experts. We will get back to you as soon as possible!",
  first_name: "First Name",
  last_name: "Last name",
  email: "Email",
  business_name: "Business Name",
  business_address: "Business Address",
  city_country: "City, Country",
  zip: "Zip Code",
  message: "Tell us about your business and how we can help you...",
  send_button: "Send Contact Details",
  success: "Message sent. We will reach soon!",
  error: "Something went wrong. Please retry.",
  info_missing:
    "The following information is required: your full name, email address, business name, and the city and country where you are located.",
};
