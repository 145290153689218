"use client";
import SectionHeader from "@/components/common/section-header";
import { FC, ReactNode, useEffect, useState } from "react";
import { DailyManagementSVG } from "./svgs/impetus";
import { CoachingSVG, MentoringSVG, TrainingSVG } from "./svgs/services";
import useInViewPort from "@/hooks/useInViewport";

import { homeWhatWeDoEN } from "@/assets/dictionaries/en/home-content";
import { homeWhatWeDoES } from "@/assets/dictionaries/es/home-content"; // Example for French

const Service: FC<{
  header: string;
  services: string[];
  extendedStyle: string;
  icon: ReactNode;
}> = ({ header, services, extendedStyle, icon }) => {
  const { ref, isInViewport } = useInViewPort({ once: true });

  return (
    <article
      className={`relative overflow-hidden p-8 h-full w-full rounded-md flex flex-col gap-4 min-w-60 drop-shadow-lg ${isInViewport ? "animate-fadein" : "opacity-0"} ${extendedStyle}`}
      ref={ref}
    >
      <h3 className="text-4xl font-bold">{header}</h3>
      <ul className="">
        {services.map((service, index) => (
          <li key={index}>
            <p className="text-xl leading-relaxed">{service}</p>{" "}
          </li>
        ))}
      </ul>

      <div className="w-60 h-60 absolute mt-4 top-1/2 -translate-y-1/2 right-0 opacity-15 -rotate-12">
        {icon}
      </div>
    </article>
  );
};

export default function WhatWeDo() {
  const [whatWeDoContent, setWhatWeDoContent] = useState(homeWhatWeDoEN); // Default to English
  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setWhatWeDoContent(homeWhatWeDoES);
        break;
      case "EN":
      default:
        setWhatWeDoContent(homeWhatWeDoEN);
        break;
    }
  }, []);

  return (
    <section className="px-page-xl py-16">
      <SectionHeader>{whatWeDoContent.title_section}</SectionHeader>

      <div className="pt-16 items-start gap-16 flex flex-col md:grid md:grid-cols-2 md:grid-rows-[repeat(3, 100px)]">
        <Service
          header={whatWeDoContent.card_1_title}
          services={whatWeDoContent.card_1_array}
          extendedStyle="bg-gradient-to-br from-white to-slate-100 col-start-1 col-end-1 row-span-2"
          icon={<DailyManagementSVG />}
        />

        <Service
          header={whatWeDoContent.card_2_title}
          services={whatWeDoContent.card_2_array}
          extendedStyle="bg-gradient-to-br from-slate-900 to-gbci-primary text-white col-start-1 col-end-1 row-span-1"
          icon={<CoachingSVG className="fill-white scale-75" />}
        />

        <Service
          header={whatWeDoContent.card_3_title}
          services={whatWeDoContent.card_3_array}
          extendedStyle="bg-gradient-to-br from-white to-slate-100 col-start-2 col-end-2 row-start-2 row-span-2"
          icon={<TrainingSVG />}
        />

        <Service
          header={whatWeDoContent.card_4_title}
          services={whatWeDoContent.card_4_array}
          extendedStyle="bg-gradient-to-br from-gbci-primary to-gbci-accent text-white col-start-2 col-end-2 row-start-1 row-span-1"
          icon={<MentoringSVG className="fill-white scale-75" />}
        />
      </div>
    </section>
  );
}
