"use client";
import Button from "@/components/common/button";
import Highlight from "@/components/common/highlight";
import Image from "next/image";
import { FC, useEffect, useState } from "react";
import gbciLogo from "../../../public/gbci-logo.png";
import bgMain3 from "../../../public/images/background/main.jpg";
import Link from "next/link";

// Import your dictionaries for multiple languages
import { homeCarouselEN } from "@/assets/dictionaries/en/home-content";
import { homeCarouselES } from "@/assets/dictionaries/es/home-content";

const MainHeroCarousel: FC = () => {
  const [carouselContent, setCarouselContent] = useState(homeCarouselEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setCarouselContent(homeCarouselES);
        break;
      case "EN":
      default:
        setCarouselContent(homeCarouselEN);
        break;
    }
  }, []);

  return (
    <section className="grid grid-cols-1 grid-rows-1 overflow-hidden">
      <div className="relative col-start-1 col-end-1 row-start-1 row-end-1">
        <div className="text-white justify-center flex flex-col gap-16 px-4 py-20 items-center 0 animate-fadein">
          <Image
            className="drop-shadow-md w-full max-w-sm"
            src={gbciLogo}
            alt="GBCI logo"
          />
          <h1 className="text-4xl sm:text-6xl text-center">
            <span className="text-4xl sm:text-6xl text-center text-gbci-primary font-bold">
              {carouselContent.title_1}
            </span>
            {carouselContent.title_2}
            <span className="text-4xl sm:text-6xl text-center text-gbci-accent font-bold">
              {" "}
              {carouselContent.title_3}
            </span>
          </h1>

          <div className="flex flex-col items-center mt-10 gap-4 text-center">
            <p>{carouselContent.featuring}</p>

            <div className="relative">
              <h2 className="text-4xl font-bold">
                {carouselContent.tool_title}
              </h2>
              <p className="font-bold absolute right-4 sm:-right-4 -top-4 bg-gbci-accent text-black px-1 rotate-6 rounded-md">
                {carouselContent.tool_title_label}
              </p>
            </div>

            <p className="max-w-lg text-xl leading-relaxed">
              {carouselContent.description}
            </p>
            <Link href="tools/isa">
              <Button size="large">{carouselContent.button}</Button>
            </Link>
            <p>{carouselContent.estimated}</p>
          </div>
        </div>

        <Image
          className="bg-slate-900 absolute left-0 top-0 h-full object-cover -z-10"
          src={bgMain3}
          alt="Background"
          placeholder="blur"
          priority={true}
        />
      </div>
    </section>
  );
};

export default MainHeroCarousel;
