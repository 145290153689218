export const homeCarouselES: { [key: string]: string } = {
  title_1: "Transformando ",
  title_2: "visiones de negocios en ",
  title_3: "realidad",
  featuring: "Presentando",
  tool_title: "Evaluación de Selección de Impetus",
  tool_title_label: "NUEVO",
  description:
    "Hemos creado una herramienta que evalúa qué tan estrechamente se alinean las expectativas de una empresa con su disposición a poner el esfuerzo, haciendo preguntas cuidadosamente seleccionadas y curadas por nuestros expertos.",
  button: "Hacer evaluación",
  estimated: "Tiempo estimado 10 minutos",
};

interface HomeWhatWeDo {
  title_section: string;
  card_1_title: string;
  card_1_array: string[];
  card_2_title: string;
  card_2_array: string[];
  card_3_title: string;
  card_3_array: string[];
  card_4_title: string;
  card_4_array: string[];
}

export const homeWhatWeDoES: HomeWhatWeDo = {
  title_section: "LO QUE HACEMOS",
  card_1_title: "Evaluación",
  card_1_array: [
    "Cultural",
    "Organizacional",
    "Operacional",
    "Tecnológica",
    "Industrial",
  ],
  card_2_title: "Coaching",
  card_2_array: [
    "Planeación estratégica",
    "Ejecución de proyectos",
    "Gestión de proyectos",
  ],
  card_3_title: "Entrenamiento",
  card_3_array: ["Lean", "Six Sigma", "Innovación", "Agile", "Liderazgo"],
  card_4_title: "Mentoría",
  card_4_array: ["Cambio de Administración", "Comunicación", "Liderazgo"],
};

export const homeWhatMakeUsES: { [key: string]: string } = {
  title_section: "LO QUE NOS FORMA",
  section_1_title: "Transformación de negocio",
  section_1_sub_title: "Repensar la tecnología",
  section_1_desc:
    "Es un rediseño radical de un flujo de valor para lograr objetivos innovadores, lo cual incluye una reevaluación del uso de la tecnología actual, el reajuste de la estructura organizacional y la mejora de los estándares de proceso actuales, ejecutados en un corto período de tiempo.",
  section_2_title: "Gestión diaria",
  section_2_sub_title: "Cambiando el lugar de trabajo",
  section_2_desc:
    "Es un sistema de gestión de operaciones basado en métricas que define una cadencia para reuniones diarias y comunicación, mejorando la responsabilidad y facilitando la escalación de problemas a los niveles correspondientes.",
  section_3_title: "Mejora contínua",
  section_3_sub_title: "Trabajando mejor. Cada día",
  section_3_desc:
    "Es un conjunto de metodologías (Kaizen, DMAIC, Workout, PDCA, RCFA) que utiliza datos o la opinión de expertos en la materia para mejorar el rendimiento del producto o proceso de manera incremental. Está organizado en muchos eventos pequeños que se planifican a lo largo de un período de tiempo extendido.",
  section_4_title: "Innovación",
  section_4_sub_title: "Haciendo lo imposible posible",
  section_4_desc:
    "Creemos que es posible buscar la innovación de manera intencional y sistemática. Ayudamos a nuestros clientes a implementar diversas metodologías para abordar sus esfuerzos de identificar o desarrollar productos y servicios sobresalientes y valiosos que interrumpan su industria o amplíen su alcance a otros mercados.",
  section_5_title: "Automatización de procesos",
  section_5_sub_title: "Haciendolo bien, haciendolo rápido",
  section_5_desc:
    "Una vez que el esfuerzo de transformación se consolida en la cultura de nuestro cliente, somos capaces de realizar una transición fluida de los procesos transformados a la automatización utilizando diversas tecnologías como RPA, IA, desarrollo de software, entre otras, para ofrecer experiencias de usuario intuitivas y valiosas a los clientes finales.",
  button_contact: "Ponte en Contacto",
};

export const homeWhatOpexES: { [key: string]: string } = {
  title_section: "¿Qué es OPEX?",
  opex_title: "Excelencia Operacional",
  opex_desc_1:
    "El esfuerzo continuo hacia los objetivos a mediano y largo plazo para transformar e innovar.",
  opex_desc_2:
    "Es un enfoque sistemático para traducir las necesidades del cliente en un producto o servicio utilizando una nueva forma de pensar, mediante el uso de nuevos materiales, diseños o incluso nueva tecnología.",
};

interface HomeOurExp {
  title_section: string;
  offices: string;
  customers: string;
  cases: string;
  americas: string;
  europe: string;
  asia: string;
  americas_array: string[];
  europe_array: string[];
  asia_array: string[];
}

export const homeOurExpES: HomeOurExp = {
  title_section: "EXPERIENCIA A NIVEL MUNDIAL",
  offices: "Oficinas",
  customers: "Clientes",
  cases: "Casos de prueba",
  americas: "América",
  europe: "Europa",
  asia: "Asia",
  americas_array: [
    "Estados Unidos",
    "Cánada",
    "México",
    "Brazil",
    "Chile",
    "Argentina",
    "Colombia",
    "Venezuela",
    "Perú",
    "Costa Rica",
  ],
  europe_array: ["Alemania", "Países bajos", "España"],
  asia_array: ["Tailandia", "Japón"],
};

export const homeContactES: { [key: string]: string } = {
  title_section: "PONERSE EN CONTACTO",
  title: "Aceleremos tu negocio juntos.",
  title_sub_title:
    "Rellena el formulario y ponte en contacto con nuestros expertos. ¡Nos pondremos en contacto contigo lo antes posible!",
  first_name: "Nombre",
  last_name: "Apellido",
  email: "Email",
  business_name: "Nombre del negocio",
  business_address: "Dirreción del negocio",
  city_country: "Ciudad, país",
  zip: "Código postal",
  message: "Cuéntanos sobre tu negocio y cómo podemos ayudarte...",
  send_button: "Enviar detalles de contacto",
  success: "¡Mensaje enviado. Nos pondremos en contacto contigo pronto!",
  error: "Algo salió mal. Por favor, inténtalo de nuevo.",
  info_missing:
    "Se requiere la siguiente información: tu nombre completo, dirección de correo electrónico, nombre del negocio y la ciudad y país en los que te encuentras.",
};
