"use client";
import Button from "@/components/common/button";
import SectionHeader from "@/components/common/section-header";
import { FC, ReactNode, useEffect, useState } from "react";
import StaffImg from "@/../public/images/figures/staff.png";

import BusinesssTransformationImg from "@/../public/images/figures/impetus/business-transformation.png";
import ContinousImprovementImg from "@/../public/images/figures/impetus/continous-improvement.png";
import DailyManagementImg from "@/../public/images/figures/impetus/daily-management.png";
import InnovationImg from "@/../public/images/figures/impetus/innovation.png";
import ProcessAutomationImg from "@/../public/images/figures/impetus/process-automation.png";

import Image from "next/image";
import useInViewPort from "@/hooks/useInViewport";
import {
  AutomationSVG,
  BusinesssTransformation,
  ContinousImprovementSVG,
  DailyManagementSVG,
  InnovationSVG,
} from "./svgs/impetus";
import Link from "next/link";

import { homeWhatMakeUsEN } from "@/assets/dictionaries/en/home-content";
import { homeWhatMakeUsES } from "@/assets/dictionaries/es/home-content"; // Example for French

const Article: FC<{
  children: ReactNode;
  header: string;
  subheader: string;
  image: ReactNode;
  CTA?: ReactNode;
  order: "left" | "right";
  iconSVG: ReactNode;
}> = ({ children, header, subheader, image, CTA, order, iconSVG }) => {
  const { ref, isInViewport } = useInViewPort<HTMLDivElement>({ once: true });

  return (
    <article
      className={`relative flex flex-col gap-8 ${order == "left" ? "lg:flex-row" : "lg:flex-row-reverse"} ${isInViewport ? "animate-fadein" : "opacity-0"}`}
      ref={ref}
    >
      <div className={`flex-1 flex flex-col gap-4 items-start`}>
        <h3 className="text-2xl font-bold uppercase text-gbci-primary flex items-center gap-3">
          {subheader}

          <div className="w-10 h-10 fill-gbci-primary">{iconSVG}</div>
        </h3>
        <h4 className="text-xl font-bold">{header}</h4>
        <p className="mt-4 mb-6 text-xl leading-relaxed">{children}</p>
        {CTA}
      </div>

      <div className="flex-1">
        <div
          className={`${order == "left" ? "lg:ml-8" : "lg:mr-8"} max-h-[500px]`}
        >
          {image}
        </div>
      </div>
    </article>
  );
};

export default function WhatMakesUs() {
  const [whatMakesUsContent, setWhatMakesUsContent] =
    useState(homeWhatMakeUsEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setWhatMakesUsContent(homeWhatMakeUsES);
        break;
      case "EN":
      default:
        setWhatMakesUsContent(homeWhatMakeUsEN);
        break;
    }
  }, []);
  return (
    <section className="px-page-xl py-16">
      <SectionHeader>{whatMakesUsContent.title_section}</SectionHeader>

      <div className="pt-16 flex flex-col gap-16">
        <Article
          header={whatMakesUsContent.section_1_sub_title}
          subheader={whatMakesUsContent.section_1_title}
          image={
            <Image
              className="h-[400px] object-cover rounded-md"
              src={BusinesssTransformationImg}
              alt=""
            />
          }
          order="left"
          // CTA={<Link href='/tool/'><Button size="large">Know my Impetus</Button></Link>}
          iconSVG={<BusinesssTransformation />}
        >
          {whatMakesUsContent.section_1_desc}
        </Article>
        <Article
          header={whatMakesUsContent.section_2_sub_title}
          subheader={whatMakesUsContent.section_2_title}
          image={
            <Image
              className="h-[400px] object-cover rounded-md"
              src={DailyManagementImg}
              alt=""
            />
          }
          order="right"
          iconSVG={<DailyManagementSVG />}
        >
          {whatMakesUsContent.section_2_desc}
        </Article>
        <Article
          header={whatMakesUsContent.section_3_sub_title}
          subheader={whatMakesUsContent.section_3_title}
          image={
            <Image
              className="h-[400px] object-cover rounded-md"
              src={ContinousImprovementImg}
              alt=""
            />
          }
          order="left"
          iconSVG={<ContinousImprovementSVG />}
        >
          {whatMakesUsContent.section_3_desc}
        </Article>
        <Article
          header={whatMakesUsContent.section_4_sub_title}
          subheader={whatMakesUsContent.section_4_title}
          image={
            <Image
              className="h-[400px] object-cover rounded-md"
              src={InnovationImg}
              alt=""
            />
          }
          order="right"
          iconSVG={<InnovationSVG />}
        >
          {whatMakesUsContent.section_4_desc}
        </Article>
        <Article
          header={whatMakesUsContent.section_5_sub_title}
          subheader={whatMakesUsContent.section_5_title}
          image={
            <Image
              className="h-[400px] object-cover rounded-md"
              src={ProcessAutomationImg}
              alt=""
            />
          }
          order="left"
          CTA={
            <Link href="/#contact">
              <Button size="large">{whatMakesUsContent.button_contact}</Button>
            </Link>
          }
          iconSVG={<AutomationSVG />}
        >
          {whatMakesUsContent.section_5_desc}
        </Article>
      </div>
    </section>
  );
}
