"use client";
import OpexDiagram from "@/../public/images/illustrations/opex-diagram.png";
import Image from "next/image";
import useInViewPort from "@/hooks/useInViewport";

import { homeWhatOpexEN } from "@/assets/dictionaries/en/home-content";
import { homeWhatOpexES } from "@/assets/dictionaries/es/home-content";
import { useEffect, useState } from "react";

export default function AboutOpex() {
  const { ref, isInViewport } = useInViewPort({ once: true });
  const [opexContent, setOpexContent] = useState(homeWhatOpexEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setOpexContent(homeWhatOpexES);
        break;
      case "EN":
      default:
        setOpexContent(homeWhatOpexEN);
        break;
    }
  }, []);

  return (
    <section className="bg-gradient-to-br from-slate-900 to-gbci-primary">
      <article
        className={`mx-page-xl flex flex-col md:flex-row gap-16 justify-center items-center py-16 ${isInViewport ? "animate-fadein" : "opacity-0"}`}
        ref={ref}
      >
        <Image
          className="flex-1 max-w-full md:max-w-[calc(100%/3*2)]"
          src={OpexDiagram}
          alt="Opex Diagram"
        />

        <div className="flex-1 flex flex-col gap-4 text-white drop-shadow-md">
          <h2 className="text-4xl font-bold">{opexContent.title_section}</h2>
          <h3 className="text-2xl font-bold">{opexContent.opex_title}</h3>

          <p className="text-xl">{opexContent.opex_desc_1}</p>
          <p className="text-xl">{opexContent.opex_desc_2}</p>
        </div>
      </article>
    </section>
  );
}
