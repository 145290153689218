"use client";
import SectionHeader from "@/components/common/section-header";
import { FC, ReactNode, useContext, useEffect, useState } from "react";
import Button from "@/components/common/button";
import useInViewPort from "@/hooks/useInViewport";
import {
  AutomationSVG,
  BusinesssTransformation,
  ContinousImprovementSVG,
  DailyManagementSVG,
  InnovationSVG,
} from "./svgs/impetus";
// import { SERVER_API_URL } from "@/constants/server";
import { AuthContext } from "@/context/AuthContext";
import Alert from "@/components/common/alert";

import { homeContactEN } from "@/assets/dictionaries/en/home-content";
import { homeContactES } from "@/assets/dictionaries/es/home-content";

const SERVER_API_URL = process.env.NEXT_PUBLIC_SERVER_API_URL;

const ChangingIcon: FC = () => {
  const svgs: ReactNode[] = [
    <BusinesssTransformation key={1} />,
    <DailyManagementSVG key={2} />,
    <ContinousImprovementSVG key={3} />,
    <InnovationSVG key={4} />,
    <AutomationSVG key={5} />,
  ];
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prev) => (prev + 1) % svgs.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex-shrink-0 fill-gbci-accent md:p-4 pl-0 h-[140px] w-[140px] md:h-[365px] md:w-[365px] flex justify-center items-center">
      {svgs[current]}
    </div>
  );
};

enum Alerts {
  NO_ALERT,
  ERROR,
  INFO_MISSING,
  SUCCESS,
}

const ContactForm: FC = () => {
  const { auth } = useContext(AuthContext);
  const { ref, isInViewport } = useInViewPort<HTMLDivElement>({ once: true });
  const [showAlert, setShowAlert] = useState(Alerts.NO_ALERT);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    business_name: "",
    business_address: "",
    city_country: "",
    zip_code: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    business_name: "",
    business_address: "",
    city_country: "",
    zip_code: "",
    message: "",
  });

  const [contactContent, setContactContent] = useState(homeContactEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setContactContent(homeContactES);
        break;
      case "EN":
      default:
        setContactContent(homeContactEN);
        break;
    }
  }, []);

  const handleChange = (value: string, label: string) => {
    // Only allow numbers in the phone field
    if (label === "zip_code") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
      setFormData({ ...formData, [label]: numericValue });
    } else {
      setFormData({ ...formData, [label]: value });
    }
    // setFormData({ ...formData, [label]: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let contact_errors = 0;

    if (!formData.first_name) {
      contact_errors = contact_errors + 1;
    }
    if (!formData.last_name) {
      contact_errors = contact_errors + 1;
    }

    if (!formData.email) {
      contact_errors = contact_errors + 1;
    } else {
      if (!formData.email.match(/^[\w-.]+(\+[\w-]*)?@([\w-]+\.)+[\w-]{2,4}$/)) {
        contact_errors = contact_errors + 1;
      }
    }

    if (!formData.business_name) {
      contact_errors = contact_errors + 1;
    }

    if (!formData.city_country) {
      contact_errors = contact_errors + 1;
    }

    if (contact_errors != 0) {
      setShowAlert(Alerts.INFO_MISSING);
      return;
    }

    const sendContactDetails = async () => {
      const messageBody = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        business_name: formData.business_name,
        business_address: formData.business_address,
        city_country: formData.city_country,
        zip_code: formData.zip_code,
        message: formData.message,
      };
      try {
        const res = await fetch(`${SERVER_API_URL}/api/v1/users/send/contact`, {
          method: "POST",
          body: JSON.stringify(messageBody),
          headers: { "Content-Type": "application/json" },
        });

        const data = await res.json();

        if (!res.ok) {
          setShowAlert(Alerts.ERROR);
        } else {
          setShowAlert(Alerts.SUCCESS);
        }
      } catch (error) {
        setShowAlert(Alerts.ERROR);
      }
    };

    sendContactDetails();
  };

  return (
    <section id="contact" className="px-page-xl py-16">
      <SectionHeader>{contactContent.title_section}</SectionHeader>
      <div
        className={`flex flex-col md:flex-row gap-8 mt-8 ${isInViewport ? "animate-fadein" : "opacity-0"}`}
        ref={ref}
      >
        <div className="flex-[2] flex flex-col items-center md:items-start gap-8 md:gap-8">
          <div className="flex flex-col gap-2">
            <h2 className="text-4xl font-bold">{contactContent.title}</h2>
            <p className="text-xl">{contactContent.title_sub_title}</p>
          </div>

          <ChangingIcon />
        </div>

        <form
          onSubmit={handleSubmit}
          className={`flex-[3] flex flex-col gap-8 `}
        >
          <div className="flex flex-wrap gap-8">
            <input
              type="text"
              placeholder={contactContent.first_name}
              className="flex-1 p-4 rounded-md border border-slate-300"
              onChange={(e) => handleChange(e.target.value, "first_name")}
            />
            <input
              type="text"
              placeholder={contactContent.last_name}
              className="flex-1 p-4 rounded-md border border-slate-300"
              onChange={(e) => handleChange(e.target.value, "last_name")}
            />
          </div>

          <input
            type="email"
            placeholder={contactContent.email}
            className="p-4 rounded-md border border-slate-300"
            onChange={(e) => handleChange(e.target.value, "email")}
          />

          <input
            type="text"
            placeholder={contactContent.business_name}
            className="p-4 rounded-md border border-slate-300"
            onChange={(e) => handleChange(e.target.value, "business_name")}
          />
          <input
            type="text"
            placeholder={contactContent.business_address}
            className="p-4 rounded-md border border-slate-300"
            onChange={(e) => handleChange(e.target.value, "business_address")}
          />
          <div className="flex flex-wrap gap-8">
            <input
              type="text"
              placeholder={contactContent.city_country}
              className="flex-1 p-4 rounded-md border border-slate-300"
              onChange={(e) => handleChange(e.target.value, "city_country")}
            />
            <input
              // type="text"
              type="tel"
              pattern="[0-9]*"
              inputMode="numeric"
              placeholder={contactContent.zip}
              value={formData.zip_code}
              className="flex-1 p-4 rounded-md border border-slate-300"
              onChange={(e) => handleChange(e.target.value, "zip_code")}
            />
          </div>
          <textarea
            placeholder={contactContent.message}
            className="flex-1 p-4 rounded-md border border-slate-300 resize-none"
            onChange={(e) => handleChange(e.target.value, "message")}
          />
          <div className="text-xl">
            <Button size="large">{contactContent.send_button}</Button>
          </div>
          {showAlert == Alerts.SUCCESS ? (
            <Alert variant="success">{contactContent.success}</Alert>
          ) : showAlert == Alerts.ERROR ? (
            <Alert variant="error">{contactContent.error}</Alert>
          ) : showAlert == Alerts.INFO_MISSING ? (
            <Alert variant="error">{contactContent.info_missing}</Alert>
          ) : null}
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
